import React from 'react';
import classes from './DecorOne.module.css';

const DecorOne = (props) => {
  return (
    <div className={classes[props.className] || classes.wrapper}>
      <div className={classes.wrapperB}>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.rectangle}></div>
        <div className={classes.clear}></div>
      </div>
    </div>
  );
};

export default DecorOne;
